import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormControl from './formControl';
import { useState } from 'react';
import Select from './select';
import useCaptcha from '../hooks/useCaptcha';
import escapeHTML from '../utils/escapeHTML';
import { ScrollToError } from './scrollToError';
import fetchData from '../utils/fetchData';

function Form() {
  const executeRecaptcha = useCaptcha();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const getCSRFToken = async () => {
    const { data } = await fetchData('/api/form', {
      withCredentials: true,
    });
    return data.csrfToken;
  };

  const Schema = Yup.object().shape({
    fullName: Yup.string().trim().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().trim().required('Required'),
    program: Yup.string()
      .required('Required')
      .oneOf(
        [
          'Customer Service Watchmaker',
          'Encasing Technician',
          'High School Summer Internship',
        ],
        'Required'
      )
      .typeError('Required'),
    address: Yup.string().trim().required('Required'),
    appQ1: Yup.string().trim().required('Required'),
    appQ2: Yup.string().trim().required('Required'),
    //appQ3: Yup.string().trim().required('Required'), // TODO - uncomment this
    appQ4: Yup.string().trim().required('Required'),
    appQ5: Yup.string().trim().required('Required'),
    appQ6: Yup.string().trim().required('Required'),
  });

  const submitValues = async (values, setSubmitting) => {
    try {
      setError('');
      const recaptchaToken = await executeRecaptcha();

      const csrfToken = await getCSRFToken();

      const result = await fetch(`api/submit`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          ...values,
          token: recaptchaToken,
        }),
      });
      if (result.status !== 200) {
        throw new Error({
          message: `Submission failed with status ${result.status}`,
        });
      }
      setSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="contact">
      {success ? (
        <p>
          Thank you for your submission! If your application meets our
          criteria, we will contact you with next steps.
        </p>
      ) : (
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            program: '-',
            phone: '',
            address: '',
            appQ1: '',
            appQ2: '',
            appQ3: '',
            appQ4: '',
            appQ5: '',
            appQ6: '',
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            const sanitized = {};
            Object.keys(values).forEach((key) => {
              sanitized[key] = escapeHTML(values[key]);
            });
            submitValues(sanitized, actions.setSubmitting);
          }}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              className="w-full max-w-[900px]"
            >
              <ScrollToError />
              <div className="form-block">
                <h2 className="text-[16px] font-medium text-center">
                  Submit your answers below to complete the first
                  phase of the application process.
                </h2>
              </div>
              <div className="form-block">
                <div className="form-section">
                  <div className="form-section_label">
                    <h2 className="text-[18px] font-bold">
                      Your contact info
                    </h2>
                  </div>
                  <div className="form-section_fields">
                    <div className="form-group">
                      <FormControl
                        label="Full Name"
                        name="fullName"
                        {...props}
                      />
                    </div>
                    <div className="form-group">
                      <FormControl
                        label="Email"
                        name="email"
                        {...props}
                      />
                    </div>
                    <div className="form-group">
                      <FormControl
                        label="Phone"
                        name="phone"
                        {...props}
                      />
                    </div>
                    <div className="form-group">
                      <FormControl
                        label="Mailing Address"
                        name="address"
                        {...props}
                      />
                    </div>
                    <div className="form-group">
                      <FormControl
                        label="Program of interest"
                        name="program"
                        as={Select}
                        options={[
                          'Customer Service Watchmaker',
                          'Encasing Technician',
                          'High School Summer Internship',
                        ]}
                        {...props}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-block">
                <div className="form-section_label">
                  <h2 className="text-[18px] font-bold">
                    Application questions
                  </h2>
                </div>

                <div className="form-group form-group--textarea">
                  <FormControl
                    label="1. The Certified Watchmaking Course at NAIOSW is rigorous. Cost of living expenses are significant. The course is full-time (40+hours a week) and there is often homework. Why is now the best time in your life to manage these various realities of attending school at NAIOSW?"
                    name="appQ1"
                    {...props}
                    as="textarea"
                  />
                </div>
                <div className="form-group form-group--textarea">
                  <FormControl
                    label="2. Successful watchmakers must have good eyes, steady hands, and good hand-eye coordination. Share with us any hobbies, experiences, or pastimes you feel are relevant to these aspects in your preparation in becoming a watchmaker?"
                    name="appQ2"
                    {...props}
                    as="textarea"
                  />
                </div>
                {/* <div className="form-group form-group--textarea"> // TODO - UNCOMMENT THIS. ALSO ADD BACK TO EMAIL TEMPLATE
                  <FormControl
                    label="3. Successful students at our school must have good eyesight, steady hands, and be physically able to sit for up to eight hours per day. Share with us any concerns you may have regarding your ability to meet the physical demands of watchmaking."
                    name="appQ3"
                    {...props}
                    as="textarea"
                  />
                </div> */}
                <div className="form-group form-group--textarea">
                  <FormControl
                    label="3. Students of NAIOSW come from a wide variety of backgrounds, personal experiences, and professional experiences. What special or unique experience(s) or skills do you have that you would like to share with us?"
                    name="appQ4"
                    {...props}
                    as="textarea"
                  />
                </div>
                <div className="form-group form-group--textarea">
                  <FormControl
                    label="4. Small class sizes and many interested candidates often results in multiple attempts to gain a seat in a watchmaking school. What would be your next steps if you did not gain a seat in the upcoming class at NAIOSW for which you are applying?"
                    name="appQ5"
                    {...props}
                    as="textarea"
                  />
                </div>
                <div className="form-group form-group--textarea">
                  <FormControl
                    label="5. There are several watchmaking schools from which to choose. Certified and non-certified, degree granting and non-degree granting, short bench courses and specialized courses, even online courses. What is your primary reason for applying at NAIOSW?"
                    name="appQ6"
                    {...props}
                    as="textarea"
                  />
                </div>
                <div className="form-section nav-button nav-button--filled max-w-full">
                  <button
                    type="submit"
                    disabled={props.isSubmitting}
                    className="w-[300px] max-w-full min-h-[40px] flex justify-center mx-auto"
                  >
                    {props.isSubmitting ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 24 24"
                        className="ml-[18px]"
                      >
                        <path
                          fill="currentColor"
                          d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                        >
                          <animateTransform
                            attributeName="transform"
                            dur="0.75s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                          />
                        </path>
                      </svg>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
                {props.errors.name && (
                  <div id="feedback">{props.errors.name}</div>
                )}
                <div className="form-section form-section--nav-button">
                  {error && (
                    <p className="error text-xs mt-4 text-center text-red-600">
                      Something went wrong, please try again.
                    </p>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default Form;
